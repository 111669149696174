<template>
  <div class="h-full">

    <e-row mr>
      <e-col>
        <erp-s-field label="Salvar alterações automaticamente" icon-help="Qualquer dado preenchido ou alterado na lista abaixo será automaticamente salvo">
          <div class="flex">
            <sl-checkbox v-model="config.autosave" /> <span v-if="updating" class="m-l font-10" style="display: inline-flex; align-items: center"><sl-loading class="size-18 m-r-xs" content="" /> Atualizando...</span>
          </div>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field label="">
          <u-btn :disable="!hasChanges" @click="save" :label="hasChanges ? 'Salvar alterações' : 'Salvo!'" icon="save" icon-type="fa" no-caps color="green" class="b-radius-3px m-r-sm" />
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field label="">
        </erp-s-field>
      </e-col>
      <e-col style="min-width: 550px" class="text-right">
        <div class="flex_ no-wrap_">
          <i class="fa fa-refresh cursor-pointer m-r text-grey" :class="{'fa-spin': table.loading}" @click="load" />
          <u-btn @click="criacaoRapidaBens(leilao)" label="Criar rapidamente" icon="add" icon-type="fa" no-caps color="blue" class="b-radius-3px m-r-sm">
            <u-tooltip :offset="[15,15]">Cuidado: Ao criar um novo lote, a lista abaixo será atualizada, caso tenha editado algum dado e não tenha salvo ele será perdido.</u-tooltip>
          </u-btn>
        <u-btn @click="selecaoBensLeilao(leilao)" label="Adicionar um bem como lote" no-caps color="grey" class="b-radius-3px m-r-sm" />
        <a @click="listOptions" class="font-10 text-grey m-b-xs" style="text-decoration: underline">Configurar lista</a>
        </div>
      </e-col>
    </e-row>
    <u-table
        ref="table"
        color="primary"
        :data="table.serverData"
        :columns="table.columns"
        :visible-columns="visibledColumns"
        :filter="table.busca"
        row-key="id"
        :pagination.sync="table.serverPagination"
        @request="request"
        :loading="table.loading"
        class="erp-table table-v2 min"
        :rows-per-page-options="[10, 20, 50]"
    >
      <!--            <template slot="top" slot-scope="props">
                  </template>-->
      <u-tr slot="header" slot-scope="props">
        <!--        <u-th auto-width v-if="mode !== 'cliente'">
                  <u-checkbox
                      v-model="props.selected"
                      :indeterminate="props.partialSelected"
                      size="xs"
                  />
                </u-th>-->
        <u-th v-for="col in props.cols" :key="col.name" :props="props" :class="'col-' + col.name" :style="col.name === 'lote' ? 'position: sticky; left: 0; z-index: 10; background-color: #FFFFFF' : ''">
          <div v-if="col.name === 'options'" class="text-center">
            <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps
                   size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
          </div>
          <template v-else>{{ col.label }}</template>
        </u-th>
      </u-tr>
      <u-tr slot="body" slot-scope="props" :props="props" style="position: relative">
        <u-td @click.native="props.row.id && bemWindow(props.row.id)" style="max-width: 70px; width: 70px" key="id" class="cursor-pointer" :props="props">
          {{props.row.id}}
        </u-td>
        <u-td @click.native="props.row.bem.id && bemWindow(props.row.bem.id)" style="max-width: 70px; width: 70px" key="bemId"  class="cursor-pointer" :props="props">
          {{props.row.bem?.id}}
        </u-td>
        <u-td @click.native="props.row.bem.comitente.id && bemWindow(props.row.bem.comitente.id)" style="max-width: 100px; width: 100px;" key="comitente" :props="props"  class="cursor-pointer">
          <div class="font-10">{{firstname(props.row.bem.comitente.pessoa.name)}}</div>
        </u-td>
        <u-td style="max-width: 100px; width: 100px;" key="comitenteId" :props="props">
          <erp-input v-model="props.row.bem.comitente.id" @keyup="event => changeField(event, props.row, 'bem.comitente.id')" />
        </u-td>
        <u-td key="lote" :props="props" style="max-width: 70px; width: 70px; position: sticky; left: 0; z-index: 10; background-color: #FFFFFF">
          <erp-input v-model="props.row.numero" @keyup="event => changeField(event, props.row, 'numero')" />
        </u-td>
        <u-td key="numeroString" :props="props" style="max-width: 90px; width: 90px">
          <erp-input v-model="props.row.numeroString" @keyup="event => changeField(event, props.row, 'numeroString')" />
        </u-td>
        <u-td key="tipo" :props="props" style="min-width: 200px">
          <div class="flex justify-between no-wrap">
<!--          <erp-input v-model="props.row.tipoBemId" />-->
          <tipo-bem-select :loaded-data="config.tiposBem" v-if="config.tiposBem" list-all tree :label="' '" class="no-label full-width" size="1" v-model="props.row.bem.tipo.id" @keyup="event => changeField(event, props.row, 'bem.tipo.id')" />
          </div>
        </u-td>
        <u-td key="siteTitulo" :props="props" style="max-width: 300px; min-width: 300px">
          <textarea v-model="props.row.bem.siteTitulo" class="sl-textarea" @keyup="event => changeField(event, props.row, 'bem.siteTitulo')" />
        </u-td>
        <u-td key="identificador" :props="props" style="min-width: 130px">
          <erp-input v-model="props.row.bem.identificador" @keyup="event => changeField(event, props.row, 'bem.identificador')" />
        </u-td>
<!--        <u-td key="marca" :props="props">
        </u-td>
        <u-td key="modelo" :props="props">
        </u-td>-->
        <u-td key="placa" :props="props" style="min-width: 100px">
          <erp-input v-model="props.row.bem.placa" @keyup="event => changeField(event, props.row, 'bem.placa')" />
        </u-td>
        <u-td key="chassi" :props="props">
          <erp-input v-model="props.row.bem.chassi" @keyup="event => changeField(event, props.row, 'bem.chassi')" />
        </u-td>
        <u-td key="valorAvaliacao" :props="props">
          <erp-input v-model="props.row.valorAvaliacao" @keyup="event => changeField(event, props.row, 'valorAvaliacao')" />
        </u-td>
        <u-td key="valorFipe" :props="props">
          <erp-input v-model="props.row.valorMercado" @keyup="event => changeField(event, props.row, 'valorMercado')" />
        </u-td>
        <u-td key="valorMinimo" :props="props">
          <erp-input v-model="props.row.valorMinimo" @keyup="event => changeField(event, props.row, 'valorMinimo')" />
        </u-td>
        <u-td key="valorInicial" :props="props">
          <erp-input v-model="props.row.valorInicial" @keyup="event => changeField(event, props.row, 'valorInicial')" />
        </u-td>
        <u-td key="valorInicial2" :props="props">
          <erp-input v-model="props.row.valorInicial2" @keyup="event => changeField(event, props.row, 'valorInicial2')" />
        </u-td>
        <u-td key="valorInicial3" :props="props">
          <erp-input v-model="props.row.valorInicial3" @keyup="event => changeField(event, props.row, 'valorInicial3')" />
        </u-td>
        <u-td key="siteSubtitulo" :props="props" style="max-width: 300px; min-width: 300px">
          <textarea v-model="props.row.bem.siteSubtitulo" class="sl-textarea" @keyup="event => changeField(event, props.row, 'bem.siteSubtitulo')" />
        </u-td>
        <u-td key="siteDescricao" :props="props" style="max-width: 300px; min-width: 300px">
          <textarea v-model="props.row.bem.siteDescricao" class="sl-textarea" @keyup="event => changeField(event, props.row, 'bem.siteDescricao')" />
        </u-td>
        <u-td key="siteObservacao" :props="props" style="max-width: 300px; min-width: 300px">
          <textarea v-model="props.row.bem.siteObservacao" class="sl-textarea" @keyup="event => changeField(event, props.row, 'bem.siteObservacao')" />
        </u-td>
        <u-td key="latitude" :props="props" style="min-width: 150px">
          <erp-input v-model="props.row.bem.latitude" @keyup="event => changeField(event, props.row, 'bem.latitude')" />
        </u-td>
        <u-td key="longitude" :props="props" style="min-width: 150px">
          <erp-input v-model="props.row.bem.longitude" @keyup="event => changeField(event, props.row, 'bem.longitude')" />
        </u-td>
        <!--<u-td key="fotos" :props="props">
          <input :ref="'fotos' + props.key" type="file" accept="image/jpeg" multiple />
        </u-td>
        <u-td key="arquivos" :props="props">
          <input :ref="'arquivos' + props.key" type="file" accept="image/pdf" multiple />
        </u-td>
        <u-td key="options" :props="props" style="max-width: 300px;">
          <input :ref="'matricula' + props.key" type="file" accept="image/pdf" multiple />
        </u-td>-->
        <u-td key="options" :props="props">
        </u-td>
      </u-tr>
    </u-table>
    <div v-if="Number(table.serverPagination.rowsNumber === 0)">Nenhum registro até o momento.</div>
  </div>
</template>

<script>
import tableColumnsCache from "@/utils/tableColumnsCache"
let debouncedUpdate = null

const listVersion = Math.random()
export const listName = 'leilao.preparacao.loteamento'
export const listStorage = tableColumnsCache(listName, [
  {label: 'ID LT', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'ID Bem', name: 'bemId', active: true, sortable: true, ordering: 1},
  {label: 'Comitente', name: 'comitente', active: true, sortable: true, ordering: 1},
  {label: 'ID Com', name: 'comitenteId', active: true, sortable: true, ordering: 1},
  {label: 'Lote', name: 'lote', active: true, sortable: true, ordering: 1},
  {label: 'Sublote', name: 'numeroString', active: true, sortable: true, ordering: 1},
  {label: 'Tipo do Bem', name: 'tipo', active: true, sortable: true, ordering: 1},
  {label: 'Título Site', name: 'siteTitulo', active: true, sortable: true, ordering: 1},
  {label: 'Matrícula', name: 'identificador', active: true, sortable: true, ordering: 1},
  //{label: 'Marca', name: 'marca', active: true, sortable: true, ordering: 1},
  //{label: 'Modelo', name: 'modelo', active: true, sortable: true, ordering: 1},
  {label: 'Placa', name: 'placa', active: true, sortable: true, ordering: 1},
  {label: 'Chassi', name: 'chassi', active: true, sortable: true, ordering: 1},
  {label: 'Avaliação', name: 'valorAvaliacao', active: true, sortable: true, ordering: 1},
  {label: 'Mercado / Fipe', name: 'valorFipe', active: true, sortable: true, ordering: 1},
  {label: 'Venda Mínima', name: 'valorMinimo', active: true, sortable: true, ordering: 1},
  {label: 'Lance Inicial', name: 'valorInicial', active: true, sortable: true, ordering: 1},
  {label: 'Lance Inicial 2', name: 'valorInicial2', active: true, sortable: true, ordering: 1},
  {label: 'Lance Inicial 3', name: 'valorInicial3', active: true, sortable: true, ordering: 1},
  {label: 'Subtítulo Site', name: 'siteSubtitulo', active: true, sortable: true, ordering: 1},
  {label: 'Descrição Site', name: 'siteDescricao', active: true, sortable: true, ordering: 1},
  {label: 'Observação Site', name: 'siteObservacao', active: true, sortable: true, ordering: 1},
  {label: 'Latitude', name: 'latitude', active: true, sortable: true, ordering: 1},
  {label: 'Longitude', name: 'longitude', active: true, sortable: true, ordering: 1},
  //{label: 'Fotos', name: 'fotos', active: true, sortable: true, ordering: 1},
  //{label: 'Arquivos', name: 'arquivos', active: true, sortable: true, ordering: 1},
], listVersion)

import listOptions from "components/usuario/v2/window/listOptions"
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage, UTooltip, UProgress, debounce} from 'uloc-vue'
import {datePtToEn} from "@/utils/date"
import {list} from "@/domain/leiloes/services/lotes"
import LeilaoMixin from "components/leiloes/components/LeilaoMixin"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import SlCheckbox from "@/reuse/input/Checkbox.vue"
import {ErpInput} from "@/plugins/uloc-erp"
import {atualizaLotesEmMassa} from "@/domain/leiloes/services/lotes"
import {atualizaLote} from "@/domain/leiloes/services"
import TipoBemSelect from "components/bem/components/include/TipoSelect.vue"
import {listTiposBem as listTiposBem2} from "@/domain/cadastro/services"
import {VMoney} from "v-money"
import {REAL_BRL} from "@/utils/money"
import loteWindow from "components/leiloes/windows/lote"
import bemWindow from "components/bem/windows/bem"
import comitenteWindow from "components/comitente/windows/comitente"
import selecaoBensLeilao from "components/leiloes/windows/selecaoBensLeilao"
import criacaoRapidaBens from "components/leiloes/windows/criarBensLeilao"
import SlLoading from "components/layout/components/Loading.vue"

const filters = {
  id: null,
  search: null
}

export default {
  name: "PreparacaoLoteamento",
  mixins: [LeilaoMixin],
  directives: {money: VMoney},
  beforeCreate() {
    this.listStorage = listStorage
  },
  data() {
    return {
      money: REAL_BRL,
      config: {
        autosave: false,
        tiposBem: null
      },
      updating: false,
      filaTransmissao: [],
      listName: listName,
      listStorage: this.listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 1, // specifying this determines pagination is server-side
          rowsPerPage: 50
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      cache: {
        comitenteSelected: null
      }
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    hasChanges () {
      return this.filaTransmissao && this.filaTransmissao.length
    }
  },
  components: {
    SlLoading,
    ErpInput,
    SlCheckbox,
    ErpSField,
    ECol,
    ERow,
    UTable,
    UTh,
    UTr,
    UTd,
    TipoBemSelect
  },
  mounted() {
    this.load()
  },
  activated() {
    this.load()
  },
  watch: {
    '$route'() {
      this.$nextTick(() => {
       this.load()
      })
    }
  },
  methods: {
    selecaoBensLeilao: selecaoBensLeilao,
    criacaoRapidaBens: criacaoRapidaBens,
    loadData () {
      return listTiposBem2(1000, 1, null, true)
          .then((response => {
            this.config.tiposBem = response.data
          }))
          .catch(error => {
            this.alertApiError(error)
          })
    },
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.leilao.id && extraFilters.push(`&leilao=${this.leilao.id}`)

      this.table.filters.search && extraFilters.push(`&search=${String(this.table.filters.search).replace('#', '_hash-')}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status.id}`)

      extraFilters.push('&complete=1')

      if (this.extraFilters && Array.isArray(this.extraFilters)) {
        this.extraFilters.map(ef => {
          extraFilters.push('&' + ef)
        })
      }

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'order'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : false}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, false)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    async load() {
      await this.loadData()
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    },
    setValue (name, f, current) {
      const keys = name.split('.')
      let value = f

      keys.forEach((key, index) => {
        value = value[key]

        if (!current[key]) {
          current[key] = {}
        }
        if (index === keys.length - 1) {
          current[key] = value
        } else {
          current = current[key]
        }
      })
    },
    changeField: function (event, f, name) {
      console.log('Alterando campo', f, f[name])
      let obj = this.filaTransmissao.find(o => o.id === f.id)
      console.log('Find: ', obj)
      if (!obj) {
        obj = {
          id: f.id
        }
        this.filaTransmissao.push(obj)
      }
      this.setValue(name, f, obj)
      //obj[name] = f[name]
      console.log(this.filaTransmissao)
      if (!this.config.autosave) return
      if (!debouncedUpdate) {
        debouncedUpdate = debounce(async () => {
          //await this.updateField(obj)
          //this.filaTransmissao.splice(this.filaTransmissao.indexOf(obj), 1)
          this.save()
        }, 2000)
      }
      return debouncedUpdate()
    },
    async updateField (f) {
      console.log('Atualizando Lote ', f.id, f)
      this.updating = true
      return new Promise((resolve, reject) => {
        atualizaLote(f.id, f, true)
          .then((response) => {
            this.updating = false
            console.log(response.data)
            resolve(response.data)
          })
          .catch(error => {
            this.updating = false
            this.alertApiError(error)
            reject(error)
          })
      })
    },
    save () {
      this.updating = true
      atualizaLotesEmMassa(this.filaTransmissao)
          .then(response => {
            this.updating = false
            this.filaTransmissao = []
          })
          .catch(error => {
            this.alertApiError(error)
            this.updating = false
          })
    },
    nl2br (v) {
      return v.replace(/\n/g, '<br>')
    },
    loteWindow: loteWindow,
    bemWindow: bemWindow,
    comitenteWindow,
    loteCriado (data) {
      if (!Array.isArray(this.table.serverData)) {
        this.table.serverData = []
      }
      //this.table.serverData.push(data.lote)
    }
  }
}
</script>
